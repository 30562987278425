/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useMemo, useState } from "react";
import SideDrawer from "../../Components/SideDrawer";
import { Button, Checkbox, Col, Row, Switch, Typography } from "antd";
import { UIDNoCredentialModal } from "../../Components/UIDNoCredentialModal";
import { UIDCredentialsModal } from "../../Components/UIDCredentialsModal";
import {
  getDeletedUIDCredentialsAPI,
  getIdentityProviderSetting,
  getUIDAnalytics,
  getUIDConnectedEmailCount,
  getUIDConsentedUsers,
  getUIDCredentialsAPI,
  getUIDUserCount,
  getUIDUserGraphData,
  getUserAuthenticationDashboardData,
  publisherCheckHiddenFeature,
  removeUIDIntegration,
  upsertIdentityProviderSetting,
  upsertUIDCredentialsAPI,
} from "../../Utils/ApiActions";
import moment from "moment";
import {
  CartesianGrid,
  Label,
  Line,
  LineChart,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";
import { identitProvidersType } from "../../Utils/Helper";
import { IdentityProviderStatsAuthStrip } from "./components/IdentityProviderStatsAuthStrip";
import { IdentityProviderlockrNMailStrip } from "./components/IdentityProviderlockrMailStrip";
import { IdentityProviderGraphStrip } from "./components/IdentityProviderlockrGraphStrip";

export function EUIDToken() {
  const [displayLoading, setDisplayLoading] = useState(false);
  const [uidCredentials, setUidCredentials] = useState(undefined);
  const [deletedCredentials, setDeletedCredentials] = useState(undefined);
  const [userCount, setUserCount] = useState(0);
  const [totalConnectedEmail, setTotalConnectedEmails] = useState(0);
  const [displayCredentialsModal, setDisplayCredentialsModal] = useState(false);
  const [endDate, setEndDate] = useState(moment());
  const [startDate, setStartDate] = useState(moment().subtract(7, "days"));
  const [lockrMailAppIDs, setLockrMailAppIDs] = useState([]);
  const [analyticsData, setAnalyticsData] = useState([]);
  const [upsertError, setUpsertError] = useState("");
  const [identityProviderSettingLoading, setidentityProviderSettingLoading] =
    useState(false);
  const [identityProviderSettings, setIdentityProviderSetting] = useState({
    dropCookie: false,
    dropLocalStorage: false,
    keepInIdentityLockr: false,
  });
  const [graphData, setGraphData] = useState([]);
  const [historyGraphData, setHistoryGraphData] = useState([]);
  const [consentedUserCount, setConsentedUserCount] = useState(0);
  const [userAuthenticationData, setUserAuthenticationData] =
    useState(undefined);
  const [userAuthDataStartDate, setUserAuthDataStartDate] = useState(
    moment().subtract(7, "days")
  );
  const [userAuthDataEndDate, setUserAuthDataEndDate] = useState(moment());

  const [graphFilterStartDate, setGraphFilterStartDate] = useState(
    moment().subtract(7, "days")
  );
  const [graphFilterEndDate, setGraphFilterEndDate] = useState(moment());
  const [loginTrendsAppIDs, setLoginTrendsAppIDs] = useState([]);
  const [authDataAppIDs, setAuthDataAppIDs] = useState([]);
  const [checkEUIDHiddenFeatureEnabled, setCheckEUIDHiddenFeatureEnabled] = useState(false);



  const ssoUserCount = useMemo(() => {
    if (userAuthenticationData?.length) {
      let result = 0;
      userAuthenticationData.forEach((item) => {
        if (item.eventType === "lockrMail") {
          result = parseInt(item.count);
        }
      });
      return result;
    }
    return 0;
  }, [userAuthenticationData]);

  const utmUserCount = useMemo(() => {
    if (userAuthenticationData?.length) {
      let result = 0;
      userAuthenticationData.forEach((item) => {
        if (item.eventType === "UTM") {
          result = parseInt(item.count);
        }
      });
      return result;
    }
    return 0;
  }, [userAuthenticationData]);

  const emailCount = useMemo(() => {
    if (userAuthenticationData?.length) {
      let result = 0;
      userAuthenticationData.forEach((item) => {
        if (item.eventType !== "UTM" && item.eventType !== "lockrMail") {
          result += parseInt(item.count);
        }
      });
      return result;
    }
    return 0;
  }, [userAuthenticationData]);

  useEffect(() => {
    window.scrollTo({
      left: 0,
      top: 0,
    });
    getUIDCredentials();
  }, []);

  useEffect(() => {
    if (uidCredentials?.accessKey) {
      getUserAuthenticationData();
    }
  }, [uidCredentials, userAuthDataEndDate, userAuthDataStartDate, authDataAppIDs]);

  async function getUserAuthenticationData() {
    try {
      const response = await getUserAuthenticationDashboardData(
        userAuthDataStartDate,
        userAuthDataEndDate,
        identitProvidersType.EUID,
        authDataAppIDs
      );
      if (response.success) {
        setUserAuthenticationData(response.data);
        return;
      }
      setUserAuthenticationData([]);
    } catch (error) {
      console.log("Unable to get the data for the user authentication");
      console.log(error);
    }
  }

  useEffect(() => {
    uidCredentials?.accessKey && getGraphData();
  }, [graphFilterEndDate, graphFilterStartDate, uidCredentials, loginTrendsAppIDs]);

  useEffect(() => {
    uidCredentials?.accessKey && getUserStats();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [uidCredentials, startDate, endDate, lockrMailAppIDs]);

  useEffect(() => {
    uidCredentials?.accessKey && getIdentityProviderSettingsCall();
    checkHiddenFeatureEUIDEnabled()
  }, [uidCredentials]);

  useEffect(() => {
    if (uidCredentials?.accessKey) {
      //   getUIDAnalyticsCall();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [startDate, endDate, uidCredentials]);

  function getUserStats() {
    getTotalUsers();
    getTotalConnectedEmailCount();
    getTotalConsentedUsers();
  }

  async function getGraphData() {
    try {
      const response = await getUIDUserGraphData(
        graphFilterStartDate,
        graphFilterEndDate,
        identitProvidersType.EUID,
        loginTrendsAppIDs
      );
      if (response.success) {
        setGraphData(response.data.data);
        setHistoryGraphData(response.data.historyData);
      }
    } catch (error) {
      console.log("Unable to get the graph data at the moment");
      console.log(error);
    }
  }

  async function getTotalConsentedUsers() {
    try {
      const response = await getUIDConsentedUsers(startDate, endDate, lockrMailAppIDs);
      if (response.success) {
        setConsentedUserCount(response.data);
      }
    } catch (error) {
      console.log("Unable to get the total consented users");
      console.log(error);
    }
  }

  function getConsentedUserPercent() {
    if (userCount != 0) {
      return (
        ((consentedUserCount ?? 1) * 100) /
        (userCount ? userCount : 1)
      ).toFixed(2);
    }
    return 0;
  }

  async function getIdentityProviderSettingsCall() {
    try {
      setidentityProviderSettingLoading(true);
      const { success, data } = await getIdentityProviderSetting(
        identitProvidersType.EUID
      );
      if (success && data) {
        setIdentityProviderSetting({
          dropCookie: data.dropCookie,
          dropLocalStorage: data.dropLocalStorage,
          keepInIdentityLockr: data.keepInIdentityLockr,
        });
      }
      setidentityProviderSettingLoading(false);
    } catch (error) {
      setidentityProviderSettingLoading(false);
      console.log(
        "Unable to get the identitty provider settings at the moment"
      );
      console.log(error);
    }
  }

  async function upsertIdentityProviderSettingCall() {
    try {
      setidentityProviderSettingLoading(true);
      const { success, data } = await upsertIdentityProviderSetting(
        identitProvidersType.EUID,
        identityProviderSettings.dropCookie,
        identityProviderSettings.dropLocalStorage,
        identityProviderSettings.keepInIdentityLockr
      );
      if (success && data) {
        setIdentityProviderSetting(data);
      }
      setidentityProviderSettingLoading(false);
      setidentityProviderSettingLoading(false);
    } catch (error) {
      console.log(
        "Unable to upsert the identity provider setting at the moment"
      );
      console.log(error);
    }
  }

  async function getUIDAnalyticsCall() {
    try {
      const resposne = await getUIDAnalytics(startDate, endDate);
      if (resposne.success) {
        const result = [];
        resposne.data.map((item, index) => {
          if (index !== resposne.data.length - 1) {
            result.push({
              ...item,
              visitorCount:
                item.visitorCount === null ? 0 : parseInt(item.visitorCount),
              tokenCount:
                item.tokenCount === null ? 0 : parseInt(item.tokenCount),
            });
          }
        });
        setAnalyticsData(result);
      }
    } catch (error) {
      console.log("Unable to get the analytics at the moment");
      console.log(error);
    }
  }

  async function getTotalConnectedEmailCount() {
    try {
      const response = await getUIDConnectedEmailCount(
        startDate,
        endDate,
        identitProvidersType.EUID,
        lockrMailAppIDs
      );
      if (response.success) {
        setTotalConnectedEmails(response.data);
      }
    } catch (error) {
      console.log("Unable to get the connected email count");
      console.log(error);
    }
  }

  async function removeUIDIntegrationCall() {
    try {
      const response = await removeUIDIntegration(identitProvidersType.EUID);
      if (response.success && response.data) {
        setDeletedCredentials({ ...uidCredentials });
        setUidCredentials(undefined);
      }
    } catch (error) {
      console.log("Unable to remove the EUID integration at the moment");
      console.log(error);
    }
  }

  async function getTotalUsers() {
    try {
      const resposne = await getUIDUserCount(
        startDate,
        endDate,
        identitProvidersType.EUID,
        lockrMailAppIDs
      );
      if (resposne.success) {
        setUserCount(resposne.data);
      }
    } catch (error) {
      console.log("Unable to get the total users at the moment");
      console.log(error);
    }
  }

  async function saveUIDCredentails(credentials) {
    setDisplayLoading(true);
    try {
      const response = await upsertUIDCredentialsAPI(
        credentials.accessKey,
        credentials.secretKey,
        identitProvidersType.EUID,
        credentials.useLockrKeys
      );
      if (!response.success) {
        setDisplayLoading(false);
        setUpsertError(response.error);
        return;
      }
      getUIDCredentials();
      setDisplayCredentialsModal(false);
    } catch (error) {
      console.log("Unable to upsert the token details");
      console.log(error);
    }
    setDisplayLoading(false);
  }

  async function getDeletedCredentials() {
    try {
      const { data, success } = await getDeletedUIDCredentialsAPI(identitProvidersType.EUID);
      if (success && data?.accessKey) {
        setDeletedCredentials({ ...data, isDeleted: false });
      }
    } catch (error) {
      console.log("Unable to get the deleted credentials at the moment");
      console.log(error);
    }
  }

  async function checkHiddenFeatureEUIDEnabled() {
    try {
      const response = await publisherCheckHiddenFeature(identitProvidersType.EUID);
      if (response.success) {
        setCheckEUIDHiddenFeatureEnabled(response.data);
      }
    } catch (error) {
      console.log("Unable to check if the EUID hidden feature is enabled");
      console.log(error);
    }
  }


  async function getUIDCredentials() {
    setDisplayLoading(true);
    try {
      const { data, success } = await getUIDCredentialsAPI(identitProvidersType.EUID);
      if (success && data?.accessKey) {
        setUidCredentials(data);
      } else {
        getDeletedCredentials();
      }
    } catch (error) {
      console.log("Unable to get the uid credentials at the moment");
      console.log(error);
    }
    setDisplayLoading(false);
  }

  function displaySwitch() {
    return (
      <div>
        <Switch
          checked={uidCredentials !== undefined}
          onChange={(checked) => {
            !checked && removeUIDIntegrationCall();
          }}
        />
        <Typography.Text
          onClick={() => {
            setDisplayCredentialsModal(true);
          }}
          className="infosum-view-credentials-text"
        >
          Settings
        </Typography.Text>
      </div>
    );
  }

  function displayConnectModal() {
    if (
      uidCredentials === undefined &&
      !displayCredentialsModal &&
      !displayLoading
    ) {
      return (
        <UIDNoCredentialModal
          integrationType={identitProvidersType.EUID}
          onOk={() => {
            setDisplayCredentialsModal(true);
          }}
          display={true}
        />
      );
    }
  }

  function displayCredModal() {
    if (displayCredentialsModal) {
      return (
        <UIDCredentialsModal
          integrationType={identitProvidersType.EUID}
          onHideError={() => {
            setUpsertError("");
          }}
          loading={displayLoading}
          error={upsertError}
          display={displayCredentialsModal}
          onOk={saveUIDCredentails}
          credentials={{
            accessKey:
              uidCredentials?.accessKey ?? deletedCredentials?.accessKey,
            secretKey:
              uidCredentials?.secretKey ?? deletedCredentials?.secretKey,
            useLockrKeys:
              checkEUIDHiddenFeatureEnabled ?
                uidCredentials?.useLockrKeys ??
                deletedCredentials?.useLockrKeys ??
                false : false,
          }}
          disableLockrKeys={!checkEUIDHiddenFeatureEnabled}
          onCancel={() => setDisplayCredentialsModal(false)}
        />
      );
    }
  }

  function getAvgConnectedEmails() {
    if (totalConnectedEmail && userCount) {
      return (totalConnectedEmail / userCount).toFixed(2);
    }
    return "0";
  }

  function renderStats() {
    return (
      <div
        style={{
          marginTop: 72,
          marginLeft: 90,
          marginRight: 90,
        }}
      >
        <IdentityProviderStatsAuthStrip
          utmCount={utmUserCount}
          ssoCount={ssoUserCount}
          emailCount={emailCount}
          allData={userAuthenticationData}
          startDate={userAuthDataStartDate}
          endDate={userAuthDataEndDate}
          onChangeEndDate={setUserAuthDataEndDate}
          onChangeStartDate={setUserAuthDataStartDate}
          setAuthDataAppIDs={setAuthDataAppIDs}
          authDataAppIDs={authDataAppIDs}
        />
        <div style={{ marginTop: 60 }} />
        <IdentityProviderlockrNMailStrip
          startDate={startDate}
          endDate={endDate}
          onChangeEndDate={setEndDate}
          onChangeStartDate={setStartDate}
          consentedPercentage={getConsentedUserPercent()}
          totalUsers={userCount}
          avgEmails={getAvgConnectedEmails()}
          lockrMailAppIDs={lockrMailAppIDs}
          setLockrMailAppIDs={setLockrMailAppIDs}
        />
        <div style={{ marginTop: 60 }} />
        <IdentityProviderGraphStrip
          historyData={historyGraphData}
          endDate={graphFilterEndDate}
          startDate={graphFilterStartDate}
          graphData={graphData}
          onChangeEndDate={setGraphFilterEndDate}
          onChangeStartDate={setGraphFilterStartDate}
          setLoginTrendsAppIDs={setLoginTrendsAppIDs}
          loginTrendsAppIDs={loginTrendsAppIDs}
        />
      </div>
    );
  }

  function renderAnalytics() {
    return (
      <div
        style={{
          marginTop: 72,
          marginLeft: 90,
          marginRight: 90,
        }}
      >
        <div style={{ display: "flex", flexDirection: "column" }}>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
            }}
          >
            <span
              style={{
                color: "white",
                fontSize: 24,
                fontWeight: "700",
                fontFamily: "Montserrat",
              }}
            >
              Analytics
            </span>
            {/* <div
              style={{
                display: "flex",
                flex: 1,
                justifyContent: "flex-end",
                alignItems: "center",
              }}
            >
              <div
                style={{
                  background: "#FAC748",
                  height: 12,
                  width: 12,
                  borderRadius: 120,
                }}
              ></div>
              <span
                style={{
                  marginLeft: 8,
                  color: "white",
                  fontSize: 12,
                  fontWeight: "400",
                  fontFamily: "Montserrat",
                }}
              >
                Consenting accounts
              </span>
              <div
                style={{
                  background: "#50E8B1",
                  height: 12,
                  width: 12,
                  borderRadius: 120,
                  marginLeft: 24,
                }}
              ></div>
              <span
                style={{
                  marginLeft: 8,
                  color: "white",
                  fontSize: 12,
                  fontWeight: "400",
                  fontFamily: "Montserrat",
                }}
              >
                lockMail login accounts
              </span> 
            </div> */}
          </div>
          <div
            style={{
              paddingTop: 30,
              paddingBottom: 30,
              background: "black",
              marginTop: 24,
              paddingRight: 30,
            }}
          >
            <ResponsiveContainer width={"100%"} height={320}>
              <LineChart
                width={500}
                height={300}
                data={analyticsData}
                margin={{
                  top: 5,
                  right: 30,
                  left: 20,
                  bottom: 5,
                }}
              >
                <CartesianGrid strokeDasharray={"3 3"} color="#353738" />
                <XAxis dataKey="rangeDate" />
                <YAxis>
                  <Label
                    dx={-18}
                    style={{ fontFamily: "Montserrat", fontSize: 14 }}
                    angle={270}
                    fill="#8B8B8B"
                    value={"Login Count"}
                  ></Label>
                </YAxis>
                <Tooltip
                  contentStyle={{ background: "#171717", border: "0" }}
                  labelStyle={{ display: "none" }}
                  wrapperStyle={{ background: "black" }}
                  formatter={(value1, value2, value3) => {
                    // console.log(value1, value2, value3)
                    let v = "";
                    let valueLabel = "";
                    if (value2 === "tokenCount") {
                      v = value3.payload?.tokenCount ?? 0;
                      valueLabel = "Consenting Accounts";
                    } else {
                      v = value3.payload?.visitorCount ?? 0;
                      valueLabel = "lockMail login accounts";
                    }
                    return [v, valueLabel];
                  }}
                ></Tooltip>
                <Line
                  dot={{ display: "none" }}
                  activeDot={{ display: "none" }}
                  type="monotone"
                  dataKey="tokenCount"
                  stroke="#FAC748"
                  strokeWidth={2}
                />
                <Line
                  dot={{ display: "none" }}
                  type="monotone"
                  dataKey="visitorCount"
                  activeDot={{ display: "none" }}
                  stroke="#50E8B1"
                  strokeWidth={2}
                />
              </LineChart>
            </ResponsiveContainer>
          </div>
        </div>
      </div>
    );
  }

  function renderSettings() {
    return (
      <Row
        justify="space-between"
        align="middle"
        className="apiandservices-newapp-btn"
      >
        <div>
          <Typography.Title className="apiandservices-heading">
            Storage Settings
          </Typography.Title>
          <div style={{ marginTop: 32 }} />
          <div style={{ display: "flex", flexDirection: "row", gap: 48 }}>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                gap: 6,
                alignItems: "center",
              }}
            >
              <Switch
                size="small"
                checked={identityProviderSettings.keepInIdentityLockr}
                onChange={(checked) => {
                  setIdentityProviderSetting({
                    ...identityProviderSettings,
                    keepInIdentityLockr: checked,
                    dropCookie: checked,
                    dropLocalStorage: checked,
                  });
                }}
              />
              <span
                style={{
                  color: "white",
                  fontSize: 16,
                  fontFamily: "Montserrat",
                }}
              >
                Keep in AIM
              </span>
            </div>
            <div style={{ display: "flex", flexDirection: "row", gap: 6 }}>
              <Checkbox
                onChange={(event) => {
                  setIdentityProviderSetting({
                    ...identityProviderSettings,
                    dropCookie: !event.target.checked,
                  });
                }}
                disabled={identityProviderSettings.keepInIdentityLockr}
                checked={!identityProviderSettings.dropCookie}
              />
              <span
                style={{
                  color: "white",
                  fontSize: 16,
                  fontFamily: "Montserrat",
                }}
              >
                Cookie
              </span>
            </div>
            <div style={{ display: "flex", flexDirection: "row", gap: 6 }}>
              <Checkbox
                onChange={(event) => {
                  setIdentityProviderSetting({
                    ...identityProviderSettings,
                    dropLocalStorage: !event.target.checked,
                  });
                }}
                disabled={identityProviderSettings.keepInIdentityLockr}
                checked={!identityProviderSettings.dropLocalStorage}
              />
              <span
                style={{
                  color: "white",
                  fontSize: 16,
                  fontFamily: "Montserrat",
                }}
              >
                Local storage
              </span>
            </div>
          </div>
        </div>
        <div>
          <Button
            loading={identityProviderSettingLoading}
            onClick={upsertIdentityProviderSettingCall}
            style={{ width: "217px" }}
            className="sign-in"
          >
            Save
          </Button>
        </div>
      </Row>
    );
  }

  return (
    <SideDrawer>
      <Row
        justify="space-between"
        align="middle"
        className="apiandservices-newapp-btn"
      >
        <Col sm={12} xs={24}>
          <Typography.Title className="apiandservices-heading">
            EUID
          </Typography.Title>
          <div style={{ marginTop: 8 }} />
          <div style={{ display: "flex", flexDirection: "row", width: "100%" }}>
            <label
              style={{
                fontSize: 14,
                fontFamily: "Montserrat",
                color: "#8B8B8B",
              }}
              className="dashboard-as-of-today"
            >
              Enable to start a connection
            </label>
            <div
              style={{
                flex: 1,
                marginLeft: 20,
                display: "flex",
                flexDirection: "row",
                width: "100%",
              }}
            >
              <div style={{ flex: 1 }}>{displaySwitch()}</div>
            </div>
          </div>
        </Col>
        <Col
          sm={12}
          xs={24}
          className="createAppButton"
          style={{ justifyContent: "flex-end", alignItems: "center" }}
        >
          {/* <div style={{ flex: 1 }}>{renderDateFilters()}</div> */}
        </Col>
      </Row>
      {renderSettings()}
      {renderStats()}
      {/* {renderAnalytics()} */}
      {displayConnectModal()}
      {displayCredModal()}
    </SideDrawer>
  );
}
